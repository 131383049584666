<template>
  <div
    v-if="!isPageLoading && currentGroupID"
    class="StartLink">
    <SimpleHeader />

    <div class="ContentWrap">
      <div class="LoginWrap">
        <div class="GroupName">{{ currentGroupData.name }}</div>
        <span class="Title">{{ mixWB('GET_STARTED') }}</span>
        <span>{{ mixWB('WE_WILL_SEND_YOU_AN_EMAIL_WITH_A_LINK_TEXT') }}</span>
        <div class="FormWrap">
          <GroupSignInForm
            :groupID="currentGroupID"
            @email-sent="onEmailSent" />
        </div>
      </div>
      <div class="InfoBox">
        <p
          class="Title"
          v-html="mixWB('INFO_BOX_TITLE_1')"/>
        <p v-html="mixWB('INFO_BOX_LINE_1')"/>
        <p v-html="mixWB('INFO_BOX_LINE_2')"/>
        <p
          class="SubLine"
          v-html="mixWB('INFO_BOX_SUB_LINE_1')"/>
        <p v-html="mixWB('INFO_BOX_LINE_3')"/>
        <p
          class="SubLine"
          v-html="mixWB('INFO_BOX_SUB_LINE_2')"/>
        <p v-html="mixWB('INFO_BOX_LINE_4')"/>
        <p
          class="SubLine"
          v-html="mixWB('INFO_BOX_SUB_LINE_3')"/>
        <p v-html="mixWB('INFO_BOX_LINE_5')"/>
        <p v-html="mixWB('INFO_BOX_LINE_6')"/>
        <p v-html="mixWB('INFO_BOX_LINE_7')"/>
        <p v-html="mixWB('INFO_BOX_LINE_8')"/>
      </div>
    </div>

    <Dialog
      :isShowing="showEmailSentDialog"
      :useComponent="LoginEmailSentModal"
      :easyClose="false"
      @close="showEmailSentDialog = false" />
  </div>
</template>

<script>
import GroupSignInForm from '@/components/Forms/GroupSignInForm.vue'
import Dialog from '@/components/Modals/Dialog'
import SimpleHeader from '@/components/SimpleHeader'
import LoginEmailSentModal from '@/components/LoginEmailSentModal'
import { mapGetters } from 'vuex'
import { markRaw } from 'vue'

export default {
  name: 'StartLink',
  data() {
    return {
      LoginEmailSentModal: markRaw(LoginEmailSentModal),
      showEmailSentDialog: false,
    }
  },
  computed: {
    ...mapGetters([
      'isPageLoading',
      'currentGroupData',
      'currentGroupID',
    ]),
  },
  watch: {
    isPageLoading(to) {
      if (!to) {
        this.checkForError()
      }
    },
  },
  methods: {
    onEmailSent() {
      this.showEmailSentDialog = true
    },
    checkForError() {
      if (this.isPageLoading) {
        return
      }

      if (this.currentGroupID) {
        return
      }

      this.$router.push({ name: 'Home' })
    },
  },
  components: {
    GroupSignInForm,
    Dialog,
    SimpleHeader,
  },
  mounted() {
    this.checkForError()
  },
}
</script>

<style lang="stylus" scoped>
  .StartLink
    padding-bottom 100px

  .ContentWrap
    display flex
    justify-content center
    align-items flex-start

  .InfoBox
    width 570px
    padding 30px 30px 50px
    background-color $color_green
    margin-left 90px
    p
      color #fff
      padding-bottom 15px
    .Title
      font-size 1.5rem
      font-weight bold
    .SubLine
      font-size 0.875rem
      font-style italic
      color $color_grey_dark

  .LoginWrap
    width 570px
    margin-top 110px
    padding 30px 30px 70px
    background-color rgba($color_grey_lightest, 0.8)
    span
      text-align center
      padding-bottom 15px
    .GroupName
      font-size 2rem
      text-align center
      margin-bottom 30px
      font-weight bold
    .Title
      font-size 1.5rem
      font-weight bold
      padding-bottom 10px
    .FormWrap
      margin 0 auto 40px
      width 380px
</style>
