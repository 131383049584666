<template>
  <form
    class="GroupSignInForm"
    @submit="onCreateSignInClick">
    <InputField
      name="email"
      :value="email"
      type="email"
      :placeholder="mixWB('ENTER_YOUR_EMAIL')"
      @on-update="onFieldUpdate" />

    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('SEND_ME_LOGIN_LINK')"
        :isLoading="isLoading"
        @button-click="onCreateSignInClick" />
    </div>
  </form>
</template>

<script>
import InputField from '@/components/FormElements/InputField'
import Button from '@/components/Buttons/Button'
import { auth } from '@/firebase/init'

export default {
  name: 'GroupSignInForm',
  emits: ['email-sent'],
  props: {
    groupID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      errorText: '',
      isLoading: false,
    }
  },
  methods: {
    onFieldUpdate(field) {
      this.errorText = ''
      this[field.name] = field.value.trim()
    },
    onCreateSignInClick(e) {
      if (e) {
        e.preventDefault()
      }

      if (this.isLoading) return
      this.errorText = ''

      // Validation
      if (!this.email) {
        this.errorText = this.mixWB('MISSING_EMAIL')
        return
      }

      this.isLoading = true
      const email = this.email.toLowerCase()

      // Sign in user
      const actionCodeSettings = {
        url: `${
          window.location.origin
        }/auto-login?email=${
          email
        }`,
        handleCodeInApp: true,
      }

      auth.sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem('emailForSignIn', email)
          this.$store.dispatch('createUserFromGroup', { email, groupID: this.groupID })

          setTimeout(() => {
            this.isLoading = false
            this.$emit('email-sent')
            this.email = ''
          }, 250)
        })
        .catch((error) => {
          this.isLoading = false
          if (error.code === 'auth/invalid-email') {
            this.errorText = this.mixWB('THE_EMAIL_YOU_ENTERED_IS_INCORRECT')
            return
          }
          this.errorText = this.mixWB('SOMETHING_WENT_WRONG_CHECK_YOUR_EMAIL_AND_TRY_AGAIN')
        })
    },
  },
  components: { InputField, Button },

}
</script>

<style lang="stylus" scoped>
  .GroupSignInForm
    display block

  .ErrorText
    display block
    margin-bottom 15px
    color $color_error
    font-size 0.875rem

  .ButtonWrap
    margin-bottom 10px
</style>
